import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import PuxTrumbowyg from '../components/shared/pux-trumbowyg'
import { Icon } from '../components/builders/icon-builder'
import { ReferencePageProps } from '../types/reference.types'
import { t } from 'ttag'
import BreadCrumbs from '../components/BreadCrumbs'
import FormBuilder from '../components/builders/form-builder'
import JirkaPhoto from '../img/jirka.png'

import PLeft from '../img/_zdroj-left.png'
import PRight from '../img/_zdroj-right.png'
import { getLocalizedUrl } from '../utils/localeURL'

const Reference = ({ data, pageContext }: ReferencePageProps) => {
  const page = data.orchard.puxDesignReference[0]
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const [isPictureLoaded, setPictuLoaded] = React.useState(false)

  let { LOCALE } = process.env
  LOCALE = LOCALE ? LOCALE : `cs`

  const handlePictureLoad = () => {
    setPictuLoaded(true)
  }

  const handleScroll = () => {
    const anchors = Array.from(document.querySelectorAll(".relative-anchor[id]"))
    const offset = window.pageYOffset;

    const filtered = anchors.filter(anchor => anchor.getBoundingClientRect().y > 5)
    const position = anchors.length - filtered.length;

    Array.from(document.querySelectorAll(`.service-nav .btn-link`)).map(link => link.classList.remove("active"));

    if (position) {
      const id = anchors[position - 1].getAttribute('id');
      document.querySelector(`.service-nav .btn-link[href*='#${id}']`)?.classList.add('active')
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <>
      <Layout localizedPath={page.localization?.localizations}>
        <PuxMetaTags {...pageContext.metaTagsData} />
        <BreadCrumbs items={pageContext.breadcrumbs} center={false} />
        <section>
          <div id="top" className="top-image case">
            <div className="images-wrap">
              <div className="images">
                <img
                  className="left"
                  src={page.puxDesignReferenceTopImagePicture?.puxMediaSelectorImage?.resizePaths[0]}
                  alt={page.displayText}
                />
                <img className="right" src={PRight} alt="" onLoad={() => handlePictureLoad()} />
              </div>
            </div>
            <div className="content-wrap">
              <div className="pux-container">
                <div className="content">
                  <h1>{page.displayText}</h1>
                  <div
                    className="perex"
                    dangerouslySetInnerHTML={{
                      __html: page.puxDesignReferenceTopImageText.html?.replace(/font-size: 1rem;/g, ``),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="case-study-condition bg-grey triangle-cross">
          <div id="stav-pred-a-po" className="relative-anchor secondary-nav-helper"></div>
          <div className="pux-container">
            <h2>{t`PuxDesign.CaseStudy.BeforeAfterHeading`}</h2>
            <div className="case-study-condition-inner">
              <div className="case-study-condition-before">
                <h3>{t`PuxDesign.CaseStudy.BeforeHeading`}</h3>
                <PuxTrumbowyg content={page.puxDesignReferenceConditionBefore} />
              </div>
              <div className="case-study-condition-after">
                <h3>{t`PuxDesign.CaseStudy.AfterHeading`}</h3>
                <PuxTrumbowyg content={page.puxDesignReferenceConditionAfter} />
              </div>
            </div>
          </div>
        </section>
        <section className={`triangle-cross triangle-cross-white service-form-section`}>
          <div id="sib-form-scroll-helper" className="relative-anchor"></div>
          <div className="pux-container narrow">
            <div className="form-info">
              <h2 className="mb-0">{t`PuxDesign.CaseStudy.Form.Heading`}</h2>
              <div className="perex">{t`PuxDesign.CaseStudy.Form.Description1`}<br />{t`PuxDesign.CaseStudy.Form.Description2`}</div>
              <div className="info-tom-wrap">
                <div className="info-tom">
                  <img src={JirkaPhoto} alt="Jiří Juhaňák"></img>
                  <strong>
                    Jiří Juhaňák
                  </strong>
                  <span> / {`${LOCALE === 'cs' ? 'KONZULTANT' : 'CONSULTANT'}`}</span>
                </div>
                <div className="info-tom-phone">
                  <a href="tel:+420605175519">
                    <Icon id="mobile" color="#FFFFFF" />
                    +420 605 175 519
                  </a>
                </div>
                <div className="info-tom-mail">
                  <a href="mailto:jiri.juhanak@puxdesign.cz">
                    <Icon id="mail" color="#FFFFFF" />
                    <span className="btn-link">jiri.juhanak@puxdesign.cz</span>
                  </a>
                </div>
              </div>
            </div>
            <FormBuilder formId="general" recaptchaUrl={pageContext.recaptchaUrl} />
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Reference

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignReference(first: 1, where: { path: $pagePath }) {
        displayText
        path
        contentItemId
        localization {
          localizations {
            ... on Orchard_PuxDesignReference {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        puxDesignReferenceTopImagePicture {
          puxMediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxDesignReferenceTopImageText {
          html
        }
        puxDesignReferenceConditionBefore {
          html
        }
        puxDesignReferenceConditionAfter {
          html
        }
      }
    }
  }
`
